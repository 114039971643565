<template>
  <validation-observer
    ref='form'
    #default='{ invalid }'
    tag='form'
    class='repeater__form'
    style='overflow: visible'
  >
    <b-modal
      v-model='show'
      content-class='package-modal rounded-8'
      dialog-class='wameed-modal-md'
      no-fade
    >
      <template slot='modal-header-close'>
        <close-icon />
      </template>
      <template slot='modal-title'>
        <div class='d-block text-center'>
          <h3 class='text-reg-18'>
            {{ $t('settings.notifications.add_filter') }}
          </h3>
        </div>
      </template>


      <b-row>
        <!--        <b-col cols='12' md='4'>-->
        <!--          <button type='button' class='mb-2 discount_on_btn'-->
        <!--                  style='width: 100%; padding: 10px'-->
        <!--                  :class="[0 === option?'active':'']"-->
        <!--                  @click='option=0'-->
        <!--          >-->
        <!--            {{ $t('settings.notifications.filter.all') }}-->
        <!--          </button>-->
        <!--        </b-col>-->

        <b-col cols='12' md='6'>
          <button type='button' class='mb-2 discount_on_btn'
                  style='width: 100%; padding: 10px'
                  :class="[1 === option?'active':'']"
                  @click='option=1'
          >
            {{ $t('settings.notifications.filter.specific') }}
          </button>
        </b-col>

        <b-col cols='12' md='6'>

          <button type='button' class='mb-2 discount_on_btn'
                  style='width: 100%; padding: 10px'
                  :class="[2 === option?'active':'']"
                  @click='option=2'
          >
            {{ $t('settings.notifications.filter.other') }}
          </button>
        </b-col>


        <!--        search users-->
        <b-col md='12' v-if='1 === option'>
          <text-input

            v-model='search'
            icon='search-icon'
            is-append
            input-classes='text-reg-14 '
            name='search'
            :placeholder="$t('common.search') + ' ...'"
            field-classes='mb-0 notification-filter-search '
          />
          <div class='w-table my-4 notification-filter-list'>
            <label class='custom-input input-bottom-border notification-filter-option'
                   v-for='(user, index) in filterUsers'
                   :key='index'
            >
              <input :value='user.id'
                     type='checkbox'
                     name='addon'
                     style='margin-top: 4px'
                     @click='addUser(user)'
              />
              <div style='width: 8px'></div>
              <name-tile
                :name='user.name'
                :image='user.image'
              />

            </label>
          </div>
        </b-col>

        <!--        filter users on age and gender-->
        <b-col md='12' v-if='2 === option'>
          <div class='my-2'>{{ $t('common.gender') }}</div>
          <div class='mb-4'>
            <label class='custom-input input-bottom-border'>
              <input :value='0' type='radio' name='gender' :checked='gender===0'
                     @click='gender=0'
              />
              <span class='text-reg-16'>{{ $t('common.all') }}</span>
            </label>
            <label class='custom-input input-bottom-border'>
              <input :value='1' type='radio' name='gender' :checked='gender===1'
                     @click='gender=1'
              />
              <span class='text-reg-16'>{{ $t('common.male') }}</span>
            </label>
            <label class='custom-input input-bottom-border'>
              <input :value='2' type='radio' name='gender' :checked='gender===2'
                     @click='gender=2'
              />
              <span class='text-reg-16'>{{ $t('common.female') }}</span>
            </label>
          </div>

          <div class='my-2'>{{ $t('common.age') }}</div>

          <div class='d-flex flex-wrap'>

            <b-col cols='12' md='6'>
              <TextInput
                :id="'option-min'"
                v-model='age.from'
                :rules='fromAgeValidations'
                field-classes='w-100'
                :label="$t('common.from')"
                :placeholder="$t('form.text.placeholder')"
                type='number'
              />
            </b-col>
            <b-col cols='12' md='6'>
              <TextInput
                :id="'option-max'"
                v-model='age.to'
                :rules='toAgeValidations'
                field-classes='w-100'
                :label="$t('common.to')"
                :placeholder="$t('form.text.placeholder')"
                type='number'
              />
            </b-col>
          </div>
        </b-col>
      </b-row>


      <template slot='modal-footer'>
        <wameed-btn
          classes='  text-med-14 text-white mx-0 rounded-8'
          :title="modalData!=null ?$t('btn.update'):$t('btn.add')"
          :disabled='invalid'
          type='submit'
          variant='main'
          @onClick='submitOrder()'
        />
        <wameed-btn
          classes='  text-med-14 text-font-secondary mx-0 rounded-8 '
          :title="$t('btn.cancel')"
          type='button'
          variant='gray'
          @onClick='closeModal()'
        />
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { WameedBtn, WameedTextInput as TextInput } from 'wameed-ui/dist/wameed-ui.esm';
import { mapGetters } from 'vuex';
import NameTile from '@/components/nameTile.table.vue';


export default {
  components: {
    NameTile,
    ValidationProvider,
    ValidationObserver,
    WameedBtn,
    TextInput
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    modalData: {
      type: Object,
      default: null
    }

  },

  data() {
    return {
      option: 1,
      gender: 0,
      age: {
        from: null,
        to: null
      },
      search: '',
      users: [],

      showSuccessModal: false
    };
  },
  computed: {
    ...mapGetters({
      getUsers: 'admin/notifications/getUsers'
    }),
    toAgeValidations() {
      if (this.age.from) {
        return 'required|max_value:100|min_value:' + this.age.from;
      }
      return '';
    },
    fromAgeValidations() {
      if (this.age.to) {
        return 'required|max_value:' + this.age.to + '|min_value:0';
      }
      return '';
    },


    filterUsers() {
      if (this.search === '')
        return this.getUsers;

      return this.getUsers.filter(user => {
        return user.name.toString().toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },

    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      }
    }
  },
  watch: {
    modalData(newVal) {
      if (newVal != null) {
        this.form = newVal;
        this.form.vendor = this.getVendors.filter(vendor => vendor.id === this.form.vendor_id)[0];

      } else {
        this.form = {
          name: '',
          image: '',
          image_web: '',
          redirect_type_obj: '',
          redirect_type: 0,
          vendor: '',
          vendor_id: '',
          redirect: '',
          start_at: '',
          end_at: ''
        };
      }
    }
  },
  created() {
    if (this.modalData != null) {
      this.form = this.modalData;
    }
  },
  methods: {


    addUser(user) {
      if (this.users.some((u) => u === user.id)) {
        this.users = this.users.filter(u => u !== user.id);
      } else {
        this.users.push(user.id);
      }
    },
    submitOrder() {
      let data = {
        option: this.option
      };
      if (this.option === 1) {
        data.users = this.users;
      } else if (this.option === 2) {
        let gender = [];
        if (this.gender === 0) {
          gender = [1, 2];
        } else {
          gender = [this.gender];
        }
        data.gender = gender;
        data.age = this.age;
      }
      this.$emit('onComplete', data);
      this.closeModal();

    },


    closeModal() {
      this.option = 1;
      this.gender = 0;
      this.age = {
        from: null,
        to: null
      };
      this.search = '';
      this.users = [];
      this.show = false;
    }
  }
};
</script>
